import React from 'react';

function Footer() {
  return (
    <footer className="site-footer">
      <h2>Contact</h2>
      <p>Stephen Campbell<br />Phone: (571) 264-5952<br />Email: campbell.t.stephen@gmail.com</p>
      <p>&copy; 2025 All rights reserved.</p>
    </footer>
  );
}

export default Footer;
