import React from 'react';

function Header() {
  return (
    <header className="site-header">
      <h1>Breathtaking Scenes from the Sky</h1>
      <p>Aerial Video in 4K Resolution</p>
    </header>
  );
}

export default Header;
