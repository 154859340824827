import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import thumbnailBayonne from './thumbnail_bayonne.jpg';
import thumbnailRuins from './thumbnail_ruins.jpg';
import thumbnailFountainebleau from './thumbnail_fountainebleau.jpg';
import thumbnailTaconypalmyra from './thumbnail_tacony-palmyra.jpg';
import thumbnailGarrett from './thumbnail_garrett.webp';
import thumbnailSamois from './thumbnail_samois.webp';
import thumbnailLatourette from './thumbnail_latourette.webp';

export const playlistsData = [
  {
    name: "Ruins",
    videos: [
      {
      url: "https://www.youtube.com/embed/dLymxm8RQ_Y",
      thumbnail: 'http://img.youtube.com/vi/dLymxm8RQ_Y/0.jpg',
      },
      {
      url: "https://www.youtube.com/embed/XBDqufDZVpQ",
      thumbnail: 'http://img.youtube.com/vi/XBDqufDZVpQ/0.jpg',
      },
      {
      url: "https://www.youtube.com/embed/TIBBmhEu_dI",
      thumbnail: 'http://img.youtube.com/vi/TIBBmhEu_dI/0.jpg',
      },
    ],
    thumbnail: thumbnailRuins, // Path to the thumbnail image
    description: "Dive into Knights Templar history as we bring you breathtaking ruins from the French forest. This Templar chapel is the current site of an ongoing archaeological excavation, with its surrounding buildings still in the process of being unearthed. This settlement dates back to the 1100s, and today it is an incredible location for aerial video.", // Playlist description
  },
  {
    name: "Bayonne Bridge",
    videos: [
      {
      url: "https://www.youtube.com/embed/ZEHU_5NFJ0g",
      thumbnail: 'http://img.youtube.com/vi/ZEHU_5NFJ0g/0.jpg',
      },
      {
      url: "https://www.youtube.com/embed/S9ut6LmJuIg",
      thumbnail: 'http://img.youtube.com/vi/S9ut6LmJuIg/0.jpg',
      },
      {
      url: "https://www.youtube.com/embed/_melZM7hQko",
      thumbnail: 'http://img.youtube.com/vi/_melZM7hQko/0.jpg',
      },
      {
      url: "https://www.youtube.com/embed/nSJf4mB-2Xg",
      thumbnail: 'http://img.youtube.com/vi/nSJf4mB-2Xg/0.jpg',
      },
      {
      url: "https://www.youtube.com/embed/wjvoztE51Jk",
      thumbnail: 'http://img.youtube.com/vi/wjvoztE51Jk/0.jpg',
      },
    ],
    thumbnail: thumbnailBayonne, // Path to the thumbnail image
    description: "Bayonne Bridge is an arch bridge spanning the Kill Van Kull and connecting Bayonne, New Jersey with Staten Island in New York City. It carries New York State Route 440 (NY 440) and New Jersey Route 440. It has the sixth-longest steel arch mainspan in the world, and this span was the longest in the world at the time of its completion. Beneath its beautiful architecture, shipping traffic floats by on the water. Both the bridge and the ships sailing below it offer an abundance of angles for aerial video.", // Playlist description
  },
  {
    name: "Fountainebleau",
    videos: [
      {
      url: "https://www.youtube.com/embed/uW6r8u2GL7A",
      thumbnail: 'http://img.youtube.com/vi/uW6r8u2GL7A/0.jpg',
      },
      {
      url: "https://www.youtube.com/embed/Az4K3YQbQEE",
      thumbnail: 'http://img.youtube.com/vi/Az4K3YQbQEE/0.jpg',
      },
      {
      url: "https://www.youtube.com/embed/LJ0BIkSbEOg",
      thumbnail: 'http://img.youtube.com/vi/LJ0BIkSbEOg/0.jpg',
      },
      {
      url: "https://www.youtube.com/embed/xvzLXVzhBeQ",
      thumbnail: 'http://img.youtube.com/vi/xvzLXVzhBeQ/0.jpg',
      },
      {
      url: "https://www.youtube.com/embed/GU6AN7hikIk",
      thumbnail: 'http://img.youtube.com/vi/GU6AN7hikIk/0.jpg',
      },
      {
      url: "https://www.youtube.com/embed/O09UHVtAmFo",
      thumbnail: 'http://img.youtube.com/vi/O09UHVtAmFo/0.jpg',
      },
      {
      url: "https://www.youtube.com/embed/KIVckU0JLmg",
      thumbnail: 'http://img.youtube.com/vi/KIVckU0JLmg/0.jpg',
      },
    ],
    thumbnail: thumbnailFountainebleau, // Path to the thumbnail image
    description: "Fontainebleau is a commune in the metropolitan area of Paris, France. It is located 55.5 kilometres (34.5 mi) south-southeast of the centre of Paris. Fontainebleau is renowned for the large and scenic forest of Fontainebleau, a favourite weekend getaway for Parisians, as well as for the historic Château de Fontainebleau, which once belonged to the kings of France. The palace, town, and forest all offer an anbundance of opportunity for incredible aerial video.", // Playlist description
  },
  {
  name: "Tacony-Palmyra Bridge",
  videos: [
    {
    url: "https://www.youtube.com/embed/07anlcJvhp8",
    thumbnail: 'http://img.youtube.com/vi/07anlcJvhp8/0.jpg',
    },
    {
    url: "https://www.youtube.com/embed/ultlwnU8BT0",
    thumbnail: 'http://img.youtube.com/vi/ultlwnU8BT0/0.jpg',
    },
    {
    url: "https://www.youtube.com/embed/3vOjJYhvEzM",
    thumbnail: 'http://img.youtube.com/vi/3vOjJYhvEzM/0.jpg',
    },
    {
    url: "https://www.youtube.com/embed/ABLUrfID5hc",
    thumbnail: 'http://img.youtube.com/vi/ABLUrfID5hc/0.jpg',
    },
    {
    url: "https://www.youtube.com/embed/vCcMKindpfs",
    thumbnail: 'http://img.youtube.com/vi/vCcMKindpfs/0.jpg',
    },
    {
    url: "https://www.youtube.com/embed/ARa8MtOTOuY",
    thumbnail: 'http://img.youtube.com/vi/ARa8MtOTOuY/0.jpg',
    },
  ],
  thumbnail: thumbnailTaconypalmyra, // Path to the thumbnail image
  description: "The Tacony–Palmyra Bridge is a combination steel tied-arch and double-leaf bascule bridge across the Delaware River that connects New Jersey Route 73 in Palmyra, New Jersey with Pennsylvania Route 73 in the Tacony section of Philadelphia. The bridge, designed by Polish-born architect Ralph Modjeski, has a total length of 3,659 feet (1,115 m) and spans 2,324 feet (708 m). After one and a half years of construction, it opened on August 14, 1929, replacing ferry service that had operated between Tacony and Palmyra since May 6, 1922. Its impressive architecture yields likewise spectacular aerial video.", // Playlist description
},
{
name: "Garrett Mountain",
videos: [
  {
  url: "https://www.youtube.com/embed/Wzj1-cY_ZPw",
  thumbnail: 'http://img.youtube.com/vi/Wzj1-cY_ZPw/0.jpg',
  },
  {
  url: "https://www.youtube.com/embed/2Peo42Gwss0",
  thumbnail: 'http://img.youtube.com/vi/2Peo42Gwss0/0.jpg',
  },
  {
  url: "https://www.youtube.com/embed/i0O58CZpksM",
  thumbnail: 'http://img.youtube.com/vi/i0O58CZpksM/0.jpg',
  },
  {
  url: "https://www.youtube.com/embed/86bBxqAPGSw",
  thumbnail: 'http://img.youtube.com/vi/86bBxqAPGSw/0.jpg',
  },
  {
  url: "https://www.youtube.com/embed/MYMTTosxuGI",
  thumbnail: 'http://img.youtube.com/vi/MYMTTosxuGI/0.jpg',
  },
],
thumbnail: thumbnailGarrett, // Path to the thumbnail image
description: "Garret Mountain Reservation (also spelled Garrett) is a 568-acre (230 ha) park located on First Watchung Mountain (Garret Mountain) in Paterson and Woodland Park (formerly West Paterson) in southern Passaic County, New Jersey. This lush nature, along with Lambert Tower, seen here upon a basalt outcrop, offer excellent opportunities for aerial video.", // Playlist description
},

  // ... more playlists
];

function VideoCarousel({ videos }) {

  const [activeIndex, setActiveIndex] = React.useState(0);

  const handleThumbnailClick = (index) => {
    setActiveIndex(index); // Set the clicked video as active
  };



  // Slider settings
  const settings = {
    // ... other settings
    dots: true,
    dotsClass: 'slick-dots custom-dots', // Add this line
    centerMode: true,
    centerPadding: '25%', // Adjust based on the size of the player and desired visibility of next/prev slides
    // other settings...
    nextArrow: <Arrow direction="next" />,
    prevArrow: <Arrow direction="prev" />,
    afterChange: current => setActiveIndex(current), // Set the active index to the current slide after change

  };

  return (
    <div className="video-carousel-container">
      <Slider {...settings}>
        {videos.map((video, index) => (
          <div key={index} className="video-slide">
            {activeIndex === index ? (
              <iframe
                src={video.url} // Ensure the video object has a 'url' property
                width="100%"
                height="360"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            ) : (
              <div onClick={() => handleThumbnailClick(index)} className="thumbnail-container">
                <img src={video.thumbnail} alt={`Thumbnail for ${video.name}`} />
              </div>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
}

function Arrow({ direction, onClick }) {
  return (
    <div className={`arrow ${direction}`} onClick={onClick} style={{ height: '360px' }}>
      <div className="arrow-bg"></div>
      <div className={`arrow-icon ${direction === "next" ? "right" : "left"}`}></div>
    </div>
  );
}



export default VideoCarousel;
