import React, { useState } from 'react';
import VideoCarousel from './VideoCarousel';
import Header from './Header';
import Footer from './Footer';
import './App.css';
import { playlistsData } from './VideoCarousel'; // import your playlists data





function App() {

  const [currentPlaylist, setCurrentPlaylist] = useState(playlistsData[0].videos);
  const [currentDescription, setCurrentDescription] = useState(playlistsData[0].description);


  // Function to change videos
  const changePlaylist = (playlist) => {
    setCurrentPlaylist(playlist.videos);
    setCurrentDescription(playlist.description);

  };

  return (
    <div className="App">
      <Header />
      <main>
        <VideoCarousel videos={currentPlaylist} />
        <div className="playlist-thumbnails">
          {playlistsData.map((playlist, index) => (
            <button key={index} onClick={() => changePlaylist(playlist)}>
              <img src={playlist.thumbnail} alt="playlist.name" />
            </button>
          ))}
        </div>
        <p className="playlist-description">{currentDescription}</p>
      </main>
      <Footer />
    </div>
  );
}

export default App;
